import React from 'react'
import LeftDivider from '../components/LeftDivider'

function Layout(props) {
  return (
    <>

        {props.children}
    </>
  )
}

export default Layout