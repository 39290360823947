import React from 'react'

function LeftDivider() {
  return (
    <div id="left-divider">
        
    </div>
  )
}

export default LeftDivider