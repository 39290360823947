import React from 'react'

function Header() {
  return (
    <header>
      <div className="header-top">
        <h1>Satya</h1>
        <h2>Grand loup protecteur</h2>
      </div>
    
    </header>
  );
}

export default Header